import axios from 'axios'
import store from "../redux/store";

const api = axios.create({
  baseURL: 'https://api.swt.inf.br'
  // baseURL: 'http://localhost:3333'
})

api.interceptors.request.use(
  config => {
    const { auth: { authToken } } = store.getState();

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    config.params = {
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6M30.Sa376ZH3FdIuQBflQwD9R9c76748tA5fc7pQWUP3YQU"
    }

    console.log("CONFIG", config)

    return config;
  },
  err => Promise.reject(err)
);

export default api