import React, { useMemo, useState, useEffect, useImperativeHandle } from "react";
import { get, merge } from "lodash";
import { useHtmlClassService, setLayoutConfig, getInitLayoutConfig } from "../../layout";

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import './styles.scss'

import { AddCircle } from '@material-ui/icons';

import api from '../../../service/api'

import Modal from '../../layout/components/modal'
import Snackbars from '../../../_metronic/layout/components/snackBar'


const localStorageActiveTabKey = "builderActiveTab";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  buttonIcon: {
    backgroundColor: 'transparent',
    border: 'none',
    height: 'auto'
  },
  card: {
    maxWidth: 270,
    minWidth: 270,
    height: 300,
    margin: 10,
  },
  media: {
    height: 140,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  button: {
    marginBottom: 10,
    marginLeft: 10,
    height: 35
  },
}));

export function News() {
  useEffect(() => {
    getNews();
  }, [])

  const activeTab = localStorage.getItem(localStorageActiveTabKey);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [type, setType] = useState('')
  const [data, setData] = useState([])
  const [dataModal, setDataModal] = useState()
  const [isOpenSnack, setIsOpenSnack] = useState(false)
  const [statusSnack, setStatusSnack] = useState('')
  const [messageSnack, setMessageSnack] = useState('')

  async function getNews() {
    try {
      const _data = await api.get('/news')
      setData(_data.data)
      console.log(_data.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function deleteNews(id) {
    try {
      await api.delete(`/news/${id}`, {
        headers: {
          Authorization: 1
        }
      })

      setMessageSnack('Noticia deletada com sucesso')
      setStatusSnack('success')
      setIsOpenSnack(true)

      setData(data.filter(news => news.id !== id))

    } catch (error) {
      setMessageSnack('Não foi possivel deletar a noticia')
      setStatusSnack('error')
      setIsOpenSnack(true)
      console.log(error)
    }
  }

  const classes = useStyles();

  const htmlClassService = useHtmlClassService();

  const initialValues = useMemo(
    () =>
      merge(
        // Fulfill changeable fields.
        getInitLayoutConfig(),
        htmlClassService.config
      ),
    [htmlClassService.config]
  );

  const saveCurrentTab = (_tab) => {
    localStorage.setItem(localStorageActiveTabKey, _tab);
  }

  function handleOpenModal(type, data) {

    setIsOpenModal(true)
    setType(type)
    setDataModal(data)
  }

  const onUpdate = (result) => {
    let newData = [...data]

    for (let i = 0; i < newData.length; i++) {
      console.log(newData[i].id, result.id)
      if (newData[i].id == result.id) {
        newData[i] = result
        break
      }
    }

    setData(newData)
  }

  const onInsert = (result) => {
    setData([...data, result])
  }

  return (
    <>
      {isOpenModal &&
        <Modal open={isOpenModal} onClose={() => setIsOpenModal(false)} type={type} data={dataModal} selectModal="news" onUpdate={onUpdate} onInsert={onInsert} />
      }

      {
        isOpenSnack && <Snackbars statusSnack={statusSnack} isOpen={isOpenSnack} message={messageSnack} onClose={() => setIsOpenSnack(false)} />
      }
      <div className="container">

        <div className="headerNewConatiner">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => handleOpenModal('add')}
          >
            Adicionar
            {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
            <AddCircle className={classes.rightIcon}>send</AddCircle>
          </Button>
        </div>

        <div className="NewConatiner">
          {
            data.map(item => (
              <Card className={classes.card}>
                <CardActionArea onClick={() => handleOpenModal('see', item)}>
                  <CardMedia
                    className={classes.media}
                    image={item.image}
                    title={item.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {item.title}
                    </Typography>
                    <p>
                      {item.text}
                    </p>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" onClick={() => handleOpenModal('see', item)}>
                    Editar
                        </Button>
                  <Button size="small" color="primary" onClick={() => deleteNews(item.id)}>
                    Excluir
                        </Button>
                </CardActions>
              </Card>
            ))
          }


        </div>
      </div>

    </>
  );
}
