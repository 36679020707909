import React from 'react'

import { MatrixProvider } from '../contexts/matrix'

const Providers = ({ children }) => {
    return (
        <MatrixProvider>
            {children}
        </MatrixProvider>
    )
}

export default Providers