import React, { useMemo, useState, useEffect } from "react";
import { get, merge } from "lodash";

import { useHtmlClassService, setLayoutConfig, getInitLayoutConfig } from "../../layout";

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Visibility, AddCircle, DeleteForever, List } from '@material-ui/icons';

import api from '../../../service/api'

import Snackbars from '../../layout/components/snackBar'

import { useParams } from "react-router-dom";
import ModalOpcao from "../../layout/components/modal-opcao";

const localStorageActiveTabKey = "builderActiveTab";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  buttonIcon: {
    backgroundColor: 'transparent',
    border: 'none',
    height: 'auto'
  }
}));

export function Options({ etapa, etapas, setData, onRemoveOption }) {
  const classes = useStyles();

  const activeTab = localStorage.getItem(localStorageActiveTabKey);
  const [key, setKey] = useState(activeTab ? +activeTab : 0);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [type, setType] = useState('')
  const [affiliated, setAffiliated] = useState([])
  const [isOpenSnack, setIsOpenSnack] = useState(false)
  const [statusSnack, setStatusSnack] = useState('')
  const [messageSnack, setMessageSnack] = useState('')

  const htmlClassService = useHtmlClassService();

  function handleOpenModal(type, data) {

    setIsOpenModal(true)
    setType(type)
    setAffiliated(data)
  }

  async function handleDelete(id) {
    try {
      await api.delete(`/chatbot/opcoes/${id}`, {
        headers: {
          Authorization: 1
        }
      })

      setMessageSnack('Etapa deletada com sucesso')
      setStatusSnack('success')
      setIsOpenSnack(true)

      // onRemoveOption(etapa.id, id)
      setData(data => {
        let dt = [...data]
        for (let et of dt) {
          if (et.id == etapa.id) {
            et.opcoes = et.opcoes.filter(o => o.id != id)
            break
          }
        }

        return dt
      })

    } catch (error) {
      setMessageSnack('Não foi possivel deletar a opção')
      setStatusSnack('error')
      setIsOpenSnack(true)
      console.log(error)
    }
  }

  const onUpdate = (result) => {
    setData(data => {
      console.log("onUpdate", data, etapa, result)
      let dt = [...data]
      for (let et of dt) {
        if (et.id == etapa.id) {
          for (let i = 0; i < et.opcoes.length; i++) {
            if (et.opcoes[i].id == result.id) {
              et.opcoes[i] = result
              console.log("KKKKK")
              break
            }
          }

          break
        }
      }

      return dt
    })
  }

  const onInsert = (result) => {
    setData(data => {
      console.log("onInsert", data, etapa, result)
      let dt = [...data]
      for (let et of dt) {
        if (et.id == etapa.id) {
          et.opcoes = [...et.opcoes, result]
          break
        }
      }
      return dt
    })
  }

  // console.log(data)

  return (
    <>
      {isOpenModal &&
        <ModalOpcao open={isOpenModal} onClose={() => setIsOpenModal(false)} etapas={etapas} etapa_id={etapa.id} type={type} data={affiliated} selectModal="branches" onUpdate={onUpdate} onInsert={onInsert} />
      }

      {
        isOpenSnack && <Snackbars statusSnack={statusSnack} isOpen={isOpenSnack} message={messageSnack} onClose={() => setIsOpenSnack(false)} />
      }
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <div style={{ height: 30 }}>
              <button className={classes.buttonIcon} onClick={() => handleOpenModal('add')}>
                <AddCircle style={{ color: '#333', margin: 10, right: 40, }} />
              </button>

            </div>

            <TableRow style={{ marginTop: 20 }}>
              <TableCell>Entrada</TableCell>
              <TableCell>Entrada / Visualização</TableCell>
              <TableCell>Próxima etapa</TableCell>
              <TableCell>Mensagem</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {etapa?.opcoes.map(item =>
              <TableRow>
                <TableCell component="th" scope="row">
                  {item.entrada}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.entrada_visualizacao}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.etapa_proxima_codigo}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.mensagem || <i style={{ fontSize: 12 }}>Sem mensagem</i>}
                </TableCell>
                <TableCell align="right">
                  <button style={
                    { backgroundColor: 'transparent', border: 'none', height: 'auto' }}
                    onClick={() => handleOpenModal('see', item)}
                  >
                    <Visibility style={{ color: '#333' }} />
                  </button>

                  <button style={
                    { backgroundColor: 'transparent', border: 'none', height: 'auto' }}
                    onClick={() => handleDelete(item.id)}
                  >
                    <DeleteForever style={{ color: '#333' }} />
                  </button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>

    </>
  );
}