import React, { useMemo, useState, useEffect } from "react";
import { get, merge } from "lodash";

import { useHtmlClassService, setLayoutConfig, getInitLayoutConfig } from "../../layout";

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Visibility, AddCircle, DeleteForever, Check, MeetingRoom } from '@material-ui/icons';

import api from '../../../service/api'

import Snackbars from '../../../_metronic/layout/components/snackBar'
import ModalTemplate from "../../layout/components/modal-template";

import { NavLink } from "react-router-dom";

const localStorageActiveTabKey = "builderActiveTab";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  buttonIcon: {
    backgroundColor: 'transparent',
    border: 'none',
    height: 'auto'
  }
}));

export function Templates() {

  const classes = useStyles();

  useEffect(() => {
    getTemplates()
  }, [])

  const activeTab = localStorage.getItem(localStorageActiveTabKey);
  const [key, setKey] = useState(activeTab ? +activeTab : 0);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [type, setType] = useState('')
  const [data, setData] = useState()
  const [affiliated, setAffiliated] = useState([])
  const [isOpenSnack, setIsOpenSnack] = useState(false)
  const [statusSnack, setStatusSnack] = useState('')
  const [messageSnack, setMessageSnack] = useState('')

  const htmlClassService = useHtmlClassService();

  async function getTemplates() {
    try {
      const _data = await api.get('/chatbot/template')
      setData(_data.data)
      console.log(_data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const initialValues = useMemo(
    () =>
      merge(
        // Fulfill changeable fields.
        getInitLayoutConfig(),
        htmlClassService.config
      ),
    [htmlClassService.config]
  );

  const saveCurrentTab = (_tab) => {
    localStorage.setItem(localStorageActiveTabKey, _tab);
  }

  function handleOpenModal(type, data) {

    setIsOpenModal(true)
    setType(type)
    setAffiliated(data)
  }

  async function handleDelete(id) {
    try {
      await api.delete(`/chatbot/template/${id}`, {
        headers: {
          Authorization: 1
        }
      })

      setMessageSnack('Filial deletada com sucesso')
      setStatusSnack('success')
      setIsOpenSnack(true)

      setData(data.filter(affiliated => affiliated.id !== id))

    } catch (error) {
      setMessageSnack('Não foi possivel deletar o template')
      setStatusSnack('error')
      setIsOpenSnack(true)
      console.log(error)
    }
  }

  const onUpdate = (result) => {
    let newData = [...data]

    for (let i = 0; i < newData.length; i++) {
      console.log(newData[i].id, result.id)
      if (newData[i].id == result.id) {
        newData[i] = result
        break
      }
    }

    setData(newData)
  }

  const onInsert = (result) => {
    setData([...data, result])
  }

  const ativarTemplate = async (template) => {
    try {
      await api.put(`/chatbot/template/ativar/${template.id}`, {
        headers: {
          Authorization: 1
        }
      })
      setData(templates => {
        for (let temp of templates) {
          temp.status = temp.id == template.id ? 1 : 2
        }
        return templates
      })
      setMessageSnack(`Template "${template.nome}" definido como ativo`)
      setStatusSnack('success')
      setIsOpenSnack(true)
    } catch (error) {
      setMessageSnack('Ocorreu um erro ao ativar o template')
      setStatusSnack('error')
      setIsOpenSnack(true)
      console.log(error)
    }
  }

  // console.log(data)

  return (
    <>
      {isOpenModal &&
        <ModalTemplate open={isOpenModal} onClose={() => setIsOpenModal(false)} type={type} data={affiliated} selectModal="branches" onUpdate={onUpdate} onInsert={onInsert} />
      }

      {
        isOpenSnack && <Snackbars statusSnack={statusSnack} isOpen={isOpenSnack} message={messageSnack} onClose={() => setIsOpenSnack(false)} />
      }

      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <div style={{ height: 30 }}>
              <button className={classes.buttonIcon} onClick={() => handleOpenModal('add')}>
                <AddCircle style={{ color: '#333', margin: 10, right: 40, }} />
              </button>

            </div>

            <TableRow style={{ marginTop: 20 }}>
              <TableCell>Nome da template</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody >
            {data?.map(item => (
              <TableRow key={item.id} style={item.status == 1 ? { backgroundColor: "rgba(60, 170, 10, 0.2)" } : {}}>
                <TableCell component="th" scope="row">
                  {item.nome}
                </TableCell>
                <TableCell align="right">
                  {item.status == 2 &&
                    <button style={
                      { backgroundColor: 'transparent', border: 'none', height: 'auto' }}
                      onClick={() => ativarTemplate(item)}
                    >
                      <Check style={{ color: '#333' }} />
                    </button>
                  }

                  <NavLink to={`/templates/${item.id}`}>
                    <button style={{ backgroundColor: 'transparent', border: 'none', height: 'auto' }}>
                      <MeetingRoom style={{ color: "#333" }} />
                    </button>
                  </NavLink>

                  <button style={
                    { backgroundColor: 'transparent', border: 'none', height: 'auto' }}
                    onClick={() => handleOpenModal('see', item)}
                  >
                    <Visibility style={{ color: '#333' }} />
                  </button>

                  <button style={
                    { backgroundColor: 'transparent', border: 'none', height: 'auto' }}
                    onClick={() => handleDelete(item.id)}
                  >
                    <DeleteForever style={{ color: '#333' }} />
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

    </>
  );
}