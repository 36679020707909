import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import * as Yup from 'yup'
// import Button from '@material-ui/core/Button';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CreateIcon from '@material-ui/icons/Create';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Snackbars from '../snackBar'

import validator from '../../helpers/validator'

import api from '../../../../service/api'
import { update } from 'timm';

import { NavLink } from "react-router-dom";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 600,
    maxWidth: '95%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
  input: {
    width: '100%',
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'spaced-between',
  },
  smallInput: {
    flex: 1
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonSave: {
    margin: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: "#fff",
  },
  buttonCloseContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    // alignItems: 'flex-end',
  },
  buttonClose: {
    backgroundColor: 'transparent',
    border: 0,
    width: 'auto',
    height: 'auto'
  },
  buttonUpload: {
    marginTop: 10,
  },
  /*modal: {
    width: '100%'
  }*/

}));

const ModalTemplate = ({ type, data, selectModal, onClose, open, onUpdate, onInsert }, ref) => {
  // const type = props.type
  // const data = props.data
  // const selectModal = props.selectModal

  useEffect(() => {
    validSee();
    visibleButton();
    // setIsEdit(false)
  }, [see, isEdit])

  const [see, setSee] = useState(false)

  // Campos Filiais
  const [nome, setNome] = useState(type === 'see' ? data?.nome : '')

  const [isEdit, setIsEdit] = useState(false)
  const [isVisibleButton, setIsVisibleButton] = useState(false)
  const [isOpenSnack, setIsOpenSnack] = useState(false)
  const [statusSnack, setStatusSnack] = useState('')
  const [messageSnack, setMessageSnack] = useState('')
  const [modalStyle] = React.useState(getModalStyle);

  const classes = useStyles();

  useEffect(() => {
    if (isEdit) {
      setIsVisibleButton(true)
    }
  }, [isEdit])

  function visibleButton() {
    if (type === 'add') {
      setIsVisibleButton(true)
    }
  }

  function validSee() {
    if (type === 'see') {
      setSee(true)
    }
  }

  async function addTemplates() {
    const data = {
      nome
    }
    try {
      const schema = {
        nome: Yup.string().required('Todos os campos são obrigatório'),
      }

      await validator(data, schema)

      let json = {
        ...data,
        template_referencia: 1
      }

      let result = await api.post('/chatbot/template', json, {
        headers: {
          Authorization: 1
        }
      })

      onClose()
      if (onInsert && result.data && result.data.data)
        onInsert(result.data.data)

      setMessageSnack('Filial cadastrado com sucesso')
      setStatusSnack('success')
      setIsOpenSnack(true)

    } catch (error) {
      setMessageSnack(error.error ? error.error : "Ocorreu um erro")
      setStatusSnack('warning')
      setIsOpenSnack(true)
    }
  }

  async function updateTemplates() {
    const payload = {
      nome
    }

    try {
      const schema = {
        nome: Yup.string().required('Todos os campos são obrigatório'),
      }

      await validator(payload, schema)

      let result = await api.put(`/chatbot/template/${data?.id}`, payload, {
        headers: {
          Authorization: 1
        }
      })

      onClose();
      if (onUpdate && result.data && result.data.data)
        onUpdate(result.data.data)

      setMessageSnack('Template atualizado com sucesso')
      setStatusSnack('success')
      setIsOpenSnack(true)

    } catch (error) {
      setMessageSnack(error.error ? error.error : "Ocorreu um erro")
      setStatusSnack('warning')
      setIsOpenSnack(true)
    }
  }

  console.log(isOpenSnack)

  return (
    <div>
      {/* <Typography gutterBottom>Click to get the full Modal experience!</Typography>
      <Button onClick={handleOpen}>Open Modal</Button> */}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        // onClose={handleClose}
        className={classes.modal}
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.buttonCloseContainer}>
            {see && <>
              <NavLink to={`/templates/${data.id}`}>
                <button className={classes.buttonClose} onClick={onClose}>
                  Abrir template
                </button>
              </NavLink>

              <button className={classes.buttonClose} onClick={() => setIsEdit(true)}>
                <CreateIcon />
              </button>
            </>}
            <button className={classes.buttonClose} onClick={onClose} >
              <HighlightOffIcon />
            </button>

          </div>

          <Typography variant="h5" id="modal-title">
            {see ? 'Template' : 'Cadastro de template'}
          </Typography>
          {/* <Typography variant="subtitle1" id="simple-modal-description">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </Typography> */}

          <TextField
            name="nome"
            id="outlined-dense"
            label="Nome"
            className={clsx(classes.textField, classes.dense, classes.input)}
            margin="dense"
            variant="outlined"
            color="secondary"
            value={nome}
            onChange={e => setNome(e.target.value)}
            disabled={isVisibleButton ? false : true}
          />

          {isVisibleButton &&
            <div className={classes.buttonContainer}>
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="Add"
                className={classes.buttonSave}
                onClick={() => type === 'add' ? addTemplates() : updateTemplates()}
              >
                Salvar
              </Fab>
            </div>
          }
        </div>
      </Modal>

      {
        isOpenSnack && <Snackbars statusSnack={statusSnack} isOpen={isOpenSnack} message={messageSnack} onClose={() => setIsOpenSnack(false)} />
      }
    </div>
  );
}

export default ModalTemplate;