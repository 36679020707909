import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { TextField, TextareaAutosize, Select } from '@material-ui/core';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import * as Yup from 'yup'
// import Button from '@material-ui/core/Button';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CreateIcon from '@material-ui/icons/Create';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Snackbars from '../snackBar'

import validator from '../../helpers/validator'

import api from '../../../../service/api'
import { update } from 'timm';

import { NavLink } from "react-router-dom";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 600,
    maxWidth: '95%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
  input: {
    width: '100%',
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'spaced-between',
  },
  smallInput: {
    flex: 1
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonSave: {
    margin: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: "#fff",
  },
  buttonCloseContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    // alignItems: 'flex-end',
  },
  buttonClose: {
    backgroundColor: 'transparent',
    border: 0,
    width: 'auto',
    height: 'auto'
  },
  buttonUpload: {
    marginTop: 10,
  },
  /*modal: {
    width: '100%'
  }*/

}));

const ModalOpcao = ({ type, data, etapa_id, selectModal, onClose, open, onUpdate, onInsert, etapas }, ref) => {
  // const type = props.type
  // const data = props.data
  // const selectModal = props.selectModal
  const [tipoEtapa, setTipoEtapa] = useState(null)

  useEffect(() => {
    validSee();
    visibleButton();
    // setIsEdit(false)
  }, [see, isEdit])

  const [loading, setLoading] = useState(false)

  const [see, setSee] = useState(false)

  // entrada, entrada_visualizacao, etapa_proxima_codigo, mensagem
  const [entrada, setEntrada] = useState(type === "see" ? data?.entrada : "")
  const [entradaVisualizacao, setEntradaVisualizacao] = useState(type === "see" ? data?.entrada_visualizacao : "")
  const [etapaProximoCodigo, setEtapaProximoCodigo] = useState(type === "see" ? (data?.etapa_proxima_codigo || "null") : "")
  const [mensagem, setMensagem] = useState(type === "see" ? data?.mensagem?.split(`\\`)?.join(`\\\\`) : "")

  const [isEdit, setIsEdit] = useState(false)
  const [isVisibleButton, setIsVisibleButton] = useState(false)
  const [isOpenSnack, setIsOpenSnack] = useState(false)
  const [statusSnack, setStatusSnack] = useState('')
  const [messageSnack, setMessageSnack] = useState('')
  const [modalStyle] = React.useState(getModalStyle);

  const classes = useStyles();

  useEffect(() => {
    if (isEdit) {
      setIsVisibleButton(true)
    }
  }, [isEdit])

  function visibleButton() {
    if (type === 'add') {
      setIsVisibleButton(true)
    }
  }

  function validSee() {
    if (type === 'see') {
      setSee(true)
      setTipoEtapa(data.tipo)
    }
  }

  async function addTemplates() {
    const data = {
      entrada,
      entrada_visualizacao: entradaVisualizacao,
      etapa_proxima_codigo: etapaProximoCodigo,
      mensagem,
    }
    try {
      setLoading(() => true)

      let json = {
        etapa_id,
        ...data
      }

      let schema = {
        entrada: Yup.string().required('Todos os campos são obrigatório'),
        entrada_visualizacao: Yup.string().required('Todos os campos são obrigatório'),
        etapa_proxima_codigo: Yup.string().required('Todos os campos são obrigatório'),
        mensagem: Yup.string().required('Todos os campos são obrigatório'),
      }

      await validator(json, schema)

      console.log(json)

      let result = await api.post('/chatbot/opcoes', json, {
        headers: {
          Authorization: 1
        }
      })

      onClose()
      if (onInsert && result.data)// && result.data.data)
        onInsert(result.data)//.data)

      setMessageSnack('Etapa cadastrada com sucesso')
      setStatusSnack('success')
      setIsOpenSnack(true)
    } catch (error) {
      console.error(error)
      setMessageSnack(error.error ? error.error : "Ocorreu um erro. Lembre-se que todos os campos são obrigatórios!")
      setStatusSnack('warning')
      setIsOpenSnack(true)
    } finally {
      setLoading(() => false)
    }
  }

  async function updateTemplates() {
    try {
      setLoading(() => true)

      let json = {
        entrada,
        entrada_visualizacao: entradaVisualizacao,
        etapa_proxima_codigo: etapaProximoCodigo,
        mensagem,
      }

      let schema = {
        entrada: Yup.string().required('Todos os campos são obrigatório'),
        entrada_visualizacao: Yup.string().required('Todos os campos são obrigatório'),
        etapa_proxima_codigo: Yup.string().required('Todos os campos são obrigatório'),
        mensagem: Yup.string().required('Todos os campos são obrigatório'),
      }

      await validator(json, schema)

      console.log(json)

      let result = await api.put(`/chatbot/opcoes/${data?.id}`, json, {
        headers: {
          Authorization: 1
        }
      })

      onClose();
      if (onUpdate && result.data)
        onUpdate(result.data)

      setMessageSnack('Template atualizado com sucesso')
      setStatusSnack('success')
      setIsOpenSnack(true)

    } catch (error) {
      console.error(error)
      setMessageSnack(error.error ? error.error : "Ocorreu um erro")
      setStatusSnack('warning')
      setIsOpenSnack(true)
    } finally {
      setLoading(() => false)
    }
  }

  console.log(isOpenSnack)

  return (
    <div>
      {/* <Typography gutterBottom>Click to get the full Modal experience!</Typography>
      <Button onClick={handleOpen}>Open Modal</Button> */}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        // onClose={handleClose}
        className={classes.modal}
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.buttonCloseContainer}>
            {see && <>
              <button className={classes.buttonClose} onClick={() => setIsEdit(true)}>
                <CreateIcon />
              </button>
            </>}

            <button className={classes.buttonClose} onClick={onClose} >
              <HighlightOffIcon />
            </button>

          </div>

          <Typography variant="h5" id="modal-title">
            {see ? 'Opção' : 'Cadastro de opção'}
          </Typography>
          {/* <Typography variant="subtitle1" id="simple-modal-description">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </Typography> */}

          <label style={{ marginTop: 10, marginBottom: -10 }}>Entrada</label>
          <TextField
            name="entrada"
            id="outlined-dense"
            className={clsx(classes.textField, classes.dense, classes.input)}
            margin="dense"
            variant="outlined"
            color="secondary"
            value={entrada}
            onChange={e => setEntrada(e.target.value)}
            disabled={isVisibleButton ? false : true}
          />

          <label style={{ marginTop: 10, marginBottom: -10 }}>Entrada Visualização</label>
          <TextField
            name="entrada_visualizacao"
            id="outlined-dense"
            className={clsx(classes.textField, classes.dense, classes.input)}
            margin="dense"
            variant="outlined"
            color="secondary"
            value={entradaVisualizacao}
            onChange={e => setEntradaVisualizacao(e.target.value)}
            disabled={isVisibleButton ? false : true}
          />

          <label style={{ marginTop: 10 }}>Mensagem</label>
          <TextareaAutosize
            name="mensagem"
            id="outlined-dense"
            label="Mensagem"
            className={clsx(classes.textField, classes.dense, classes.input)}
            margin="dense"
            variant="outlined"
            color="secondary"
            value={mensagem}
            onChange={e => setMensagem(e.target.value)}
            disabled={isVisibleButton ? false : true}
          />

          <label style={{ marginTop: 10 }}>Próxima etapa</label>
          <Select
            name="etapa_proxima_codigo"
            id="outlined-dense"
            label="Mensagem"
            className={clsx(classes.textField, classes.dense, classes.input)}
            margin="dense"
            variant="outlined"
            color="secondary"
            value={etapaProximoCodigo}
            onChange={e => setEtapaProximoCodigo(e.target.value)}
            disabled={isVisibleButton ? false : true}
          >
            <option value="null" style={{ padding: 10, cursor: "pointer", fontSize: 14 }}>Não ir para nenhuma etapa</option>
            {etapas.map((etapa, index) => <option key={index} value={etapa.codigo} style={{ padding: 10, cursor: "pointer", fontSize: 14 }}>{etapa.codigo}</option>)}
          </Select>

          {isVisibleButton &&
            <div className={classes.buttonContainer}>
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="Add"
                className={classes.buttonSave}
                onClick={() => loading ? null : (type === 'add' ? addTemplates() : updateTemplates())}
              >
                {loading ? "Carregando..." : "Salvar"}
              </Fab>
            </div>
          }
        </div>
      </Modal>

      {
        isOpenSnack && <Snackbars statusSnack={statusSnack} isOpen={isOpenSnack} message={messageSnack} onClose={() => setIsOpenSnack(false)} />
      }
    </div>
  );
}

export default ModalOpcao;