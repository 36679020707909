import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
//import {MyPage} from "./pages/MyPage";
import { NewsPage } from "./pages/News"
import { TemplatesPage } from "./pages/TemplatesPage";
import { TemplatePage } from "./pages/TemplatePage";
import { Relatorio } from "../_metronic/_partials/relatorio";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/filiais" />
                }
                {/*<ContentRoute path="/dashboard" component={DashboardPage} />*/}
                <ContentRoute path="/filiais" component={BuilderPage} />
                {/*<ContentRoute path="/my-page" component={MyPage}/>*/}
                <ContentRoute path="/noticias" component={NewsPage} />
                <ContentRoute path="/templates/:template_id" component={TemplatePage} />
                <ContentRoute path="/templates" component={TemplatesPage} />
                <ContentRoute path="/relatorio" component={Relatorio} />
                {/* <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/> */}
                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
}
