/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, useContext } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import MatrixContext from "../contexts/matrix";
// import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import api from '../service/api'

export function Routes() {
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    const { setMatrix } = useContext(MatrixContext)

    // const isAuthorized = {
    //     name: "joao",
    //     accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwiaWF0IjoxNjEzOTU2NzAzLCJleHAiOjE2MTQwNDMxMDN9.C-SZ14lJ8rTBjwkcZKyQr0hMU2lAfc08BfOeONZEOeE",
    // }

    console.log(isAuthorized)

    useEffect(() => {
        findMatrix()
    }, [])

    const findMatrix = async () => {
        try {
            const result = await api.get('/matrix/session')
            console.log("EMPRESA", result.data)
            setMatrix(result.data)
        } catch (error) {
        }
    }

    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/" />
                )}

            {/* <Route path="/error" component={ErrorsPage}/> */}
            <Route path="/logout" component={Logout} />


            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : (
                    <Layout>
                        <BasePage />
                    </Layout>
                )}
        </Switch>
    );
}
