import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import * as Yup from 'yup'
// import Button from '@material-ui/core/Button';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CreateIcon from '@material-ui/icons/Create';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Snackbars from '../snackBar'

import validator from '../../helpers/validator'

import api from '../../../../service/api'
import { update } from 'timm';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 600,
    maxWidth: '95%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
  input: {
    width: '100%',
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'spaced-between',
  },
  smallInput: {
    flex: 1
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonSave: {
    margin: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: "#fff",
  },
  buttonCloseContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    // alignItems: 'flex-end',
  },
  buttonClose: {
    backgroundColor: 'transparent',
    border: 0,
    width: 'auto',
    height: 'auto'
  },
  buttonUpload: {
    marginTop: 10,
  },
  /*modal: {
    width: '100%'
  }*/

}));

const SimpleModal = ({ type, data, selectModal, onClose, open, onUpdate, onInsert }, ref) => {
  // const type = props.type
  // const data = props.data
  // const selectModal = props.selectModal

  const refInputUpload = useRef(null)

  useEffect(() => {
    validSee();
    visibleButton();
    // setIsEdit(false)
  }, [see, isEdit])

  const [see, setSee] = useState(false)

  // Campos Filiais
  const [name, setName] = useState(type === 'see' ? data?.name : '')
  const [address, setAddress] = useState(type === 'see' ? data?.address : '')
  const [phone, setPhone] = useState(type === 'see' ? data?.phone : '')
  const [email, setEmail] = useState(type === 'see' ? data?.email : '')
  const [whatsapp, setWhatsapp] = useState(type === 'see' ? data?.whatsapp : '')

  // Campos Noticias
  const [title, setTitle] = useState(type === 'see' ? data?.title : '')
  const [text, setText] = useState(type === 'see' ? data?.text : '')

  const [isEdit, setIsEdit] = useState(false)
  const [isVisibleButton, setIsVisibleButton] = useState(false)
  const [isOpenSnack, setIsOpenSnack] = useState(false)
  const [statusSnack, setStatusSnack] = useState('')
  const [messageSnack, setMessageSnack] = useState('')
  const [modalStyle] = React.useState(getModalStyle);

  // Base 64
  const [uploadFile, setUploadFile] = useState(null)

  const classes = useStyles();

  useEffect(() => {
    if (isEdit) {
      setIsVisibleButton(true)
    }
  }, [isEdit])

  function visibleButton() {
    if (type === 'add') {
      setIsVisibleButton(true)
    }
  }

  function validSee() {
    if (type === 'see') {
      setSee(true)
    }
  }

  const handleChangeImage = (e) => {
    let files = e.target.files

    if (files.length > 0) {
      let file = files[0]

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setUploadFile({
          file: file,
          base64: reader.result
        })
      };
    }
  }

  async function addBranches() {
    const data = {
      name,
      address,
      phone,
      email,
      whatsapp
    }
    try {
      const schema = {
        name: Yup.string().required('Todos os campos são obrigatório'),
        address: Yup.string().required('Todos os campos são obrigatório'),
        phone: Yup.string().required('Todos os campos são obrigatório'),
        email: Yup.string().email('Informe um endereço de email válido').required('Informe um endereço de email válido'),
        whatsapp: Yup.string().required('Todos os campos são obrigatório'),
      }

      await validator(data, schema)

      let result = await api.post('/affiliated', data, {
        headers: {
          Authorization: 1
        }
      })

      onClose()
      if (onInsert && result.data && result.data.data)
        onInsert(result.data.data)

      setMessageSnack('Filial cadastrada com sucesso')
      setStatusSnack('success')
      setIsOpenSnack(true)

    } catch (error) {
      setMessageSnack(error.error ? error.error : "Ocorreu um erro")
      setStatusSnack('warning')
      setIsOpenSnack(true)
    }
  }

  async function updateBranches() {
    const payload = {
      name,
      address,
      phone,
      email,
      whatsapp
    }

    try {
      const schema = {
        name: Yup.string().required('Todos os campos são obrigatório'),
        address: Yup.string().required('Todos os campos são obrigatório'),
        phone: Yup.string().required('Todos os campos são obrigatório'),
        email: Yup.string().email('Informe um endereço de email válido').required('Informe um endereço de email válido'),
        whatsapp: Yup.string().required('Todos os campos são obrigatório'),
      }

      await validator(payload, schema)

      let result = await api.put(`/affiliated/${data?.id}`, payload, {
        headers: {
          Authorization: 1
        }
      })

      onClose();
      if (onUpdate && result.data && result.data.data)
        onUpdate(result.data.data)

      setMessageSnack('Filial atualizada com sucesso')
      setStatusSnack('success')
      setIsOpenSnack(true)

    } catch (error) {
      setMessageSnack(error.error ? error.error : "Ocorreu um erro")
      setStatusSnack('warning')
      setIsOpenSnack(true)
    }
  }

  async function addNews() {
    let data = {
      title,
      text
    }

    if (uploadFile) data.image = uploadFile.base64

    try {

      const schema = {
        title: Yup.string().required('Todos os campos são obrigatório'),
        text: Yup.string().required('Todos os campos são obrigatório'),
      }

      await validator(data, schema)

      let result = await api.post('/news', data, {
        headers: {
          Authorization: 1
        }
      })

      onClose()
      if (onInsert && result.data && result.data.data)
        onInsert(result.data.data)

      setMessageSnack('Notícia cadastrada com sucesso')
      setStatusSnack('success')
      setIsOpenSnack(true)
    } catch (error) {
      setMessageSnack(error.error ? error.error : "Ocorreu um erro")
      setStatusSnack('warning')
      setIsOpenSnack(true)
    }
  }

  async function updateNews() {
    let payload = {
      title,
      text
    }

    if (uploadFile) payload.image = uploadFile.base64

    console.log(payload)
    console.log(text)

    try {
      const schema = {
        title: Yup.string().required('Todos os campos são obrigatório'),
        text: Yup.string().required('Todos os campos são obrigatório'),
      }

      await validator(payload, schema)

      let updated = await api.put(`/news/${data?.id}`, payload, {
        headers: {
          Authorization: 1
        }
      })

      onClose();
      if (onUpdate && updated.data && updated.data.data)
        onUpdate(updated.data.data);

      setMessageSnack('Noticia atualizada com sucesso')
      setStatusSnack('success')
      setIsOpenSnack(true)

    } catch (error) {
      setMessageSnack(error.error ? error.error : "Ocorreu um erro")
      setStatusSnack('warning')
      setIsOpenSnack(true)
    }
  }

  console.log(isOpenSnack)

  const ModalBranches = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.buttonCloseContainer}>
        {see &&
          <button className={classes.buttonClose} onClick={() => setIsEdit(true)}>
            <CreateIcon />
          </button>
        }
        <button className={classes.buttonClose} onClick={() => onClose()} >
          <HighlightOffIcon />
        </button>

      </div>

      <Typography variant="h5" id="modal-title">
        {see ? 'Filiais' : 'Cadastro de filiais'}
      </Typography>
      {/* <Typography variant="subtitle1" id="simple-modal-description">
      Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </Typography> */}

      <TextField
        name="name"
        id="outlined-dense"
        label="Nome"
        className={clsx(classes.textField, classes.dense, classes.input)}
        margin="dense"
        variant="outlined"
        color="secondary"
        value={name}
        onChange={e => setName(e.target.value)}
        disabled={isVisibleButton ? false : true}
      />
      <TextField
        name="address"
        id="outlined-dense"
        label="Endereço"
        className={clsx(classes.textField, classes.dense, classes.input)}
        margin="dense"
        variant="outlined"
        color="secondary"
        value={address}
        onChange={e => setAddress(e.target.value)}
        disabled={isVisibleButton ? false : true}
      />
      <div className={classes.inputContainer}>
        <TextField
          id="outlined-dense"
          label="Fone"
          className={clsx(classes.textField, classes.dense, classes.smallInput)}
          style={{ marginRight: '2.5%' }}
          margin="dense"
          variant="outlined"
          color="secondary"
          value={phone}
          onChange={e => setPhone(e.target.value)}
          disabled={isVisibleButton ? false : true}
        />
        <TextField
          id="outlined-dense"
          label="Whatsapp"
          className={clsx(classes.textField, classes.dense, classes.smallInput)}
          style={{ marginLeft: '2.5%' }}
          margin="dense"
          variant="outlined"
          color="secondary"
          value={whatsapp}
          onChange={e => setWhatsapp(e.target.value)}
          disabled={isVisibleButton ? false : true}
        />
      </div>

      <TextField
        id="outlined-dense"
        label="Email"
        className={clsx(classes.textField, classes.dense, classes.input)}
        margin="dense"
        variant="outlined"
        color="secondary"
        value={email}
        onChange={e => setEmail(e.target.value)}
        disabled={isVisibleButton ? false : true}
      />

      {isVisibleButton &&
        <div className={classes.buttonContainer}>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="Add"
            className={classes.buttonSave}
            onClick={() => type === 'add' ? addBranches() : updateBranches()}
          >
            Salvar
          </Fab>
        </div>
      }
    </div>
  )

  const ModalNews = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.buttonCloseContainer}>
        {see && !isEdit &&
          <button className={classes.buttonClose} onClick={() => setIsEdit(true)}>
            <CreateIcon />
          </button>
        }
        <button className={classes.buttonClose} onClick={() => onClose()} >
          <HighlightOffIcon />
        </button>

      </div>

      <Typography variant="h5" id="modal-title">
        {see ? 'Noticias' : 'Cadastro de noticias'}
      </Typography>
      {/* <Typography variant="subtitle1" id="simple-modal-description">
      Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </Typography> */}
      {data && data.image && !uploadFile && <img src={data.image} style={{ maxWidth: '100%', marginTop: 10, marginBottom: 10 }} />}

      <TextField
        name="title"
        id="outlined-dense"
        label="Título"
        className={clsx(classes.textField, classes.dense, classes.input)}
        margin="dense"
        variant="outlined"
        color="secondary"
        value={title}
        onChange={e => setTitle(e.target.value)}
        disabled={isVisibleButton ? false : true}
      />
      <TextField
        name="text"
        id="outlined-dense"
        label="Texto"
        className={clsx(classes.textField, classes.dense, classes.input)}
        margin="dense"
        variant="outlined"
        multiline
        color="secondary"
        value={text}
        onChange={e => setText(e.target.value)}
        disabled={isVisibleButton ? false : true}
        rows={6}
      />

      {isVisibleButton && <Button variant="contained" color="default" className={classes.buttonUpload} onClick={() => { refInputUpload.current.click() }}>
        Upload de imagem
        <CloudUploadIcon className={classes.rightIcon} />
        <input type="file" className="image" accept=".jpeg, .jpg, .png" style={{ display: 'none' }} ref={refInputUpload} onChange={handleChangeImage} />
      </Button>}

      {uploadFile &&
        <div className="d-flex mt-3" style={{ justifyContent: 'space-between', borderWidth: 1, borderColor: '#333', borderRadius: 0.6 }}>
          <p>{uploadFile.file.name}</p>
          <a onClick={() => { setUploadFile(null) }}>Excluir</a>
        </div>
      }

      {isVisibleButton &&
        <div className={classes.buttonContainer}>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="Add"
            className={classes.buttonSave}
            onClick={() => type === 'add' ? addNews() : updateNews()}
          >
            Salvar
          </Fab>
        </div>
      }
    </div>
  )

  return (
    <div>
      {/* <Typography gutterBottom>Click to get the full Modal experience!</Typography>
      <Button onClick={handleOpen}>Open Modal</Button> */}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        // onClose={handleClose}
        className={classes.modal}
      >
        {selectModal === 'news' ? ModalNews : ModalBranches}
      </Modal>

      {
        isOpenSnack && <Snackbars statusSnack={statusSnack} isOpen={isOpenSnack} message={messageSnack} onClose={() => setIsOpenSnack(false)} />
      }
    </div>
  );
}

export default SimpleModal;