import React, { useState, createContext } from 'react'

const MatrixContext = createContext({})

export const MatrixProvider = ({ children }) => {
    const [matrix, setMatrix] = useState(null)

    return (
        <MatrixContext.Provider value={{
            matrix, setMatrix
        }}>
            {children}
        </MatrixContext.Provider>
    )
}

export default MatrixContext