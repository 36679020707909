import React, { useMemo, useState, useEffect } from "react";
import { get, merge } from "lodash";

import { useHtmlClassService, setLayoutConfig, getInitLayoutConfig } from "../../layout";

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Row, Col } from "react-bootstrap";

import api from '../../../service/api'

const localStorageActiveTabKey = "builderActiveTab";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  buttonIcon: {
    backgroundColor: 'transparent',
    border: 'none',
    height: 'auto'
  }
}));

export function Relatorio() {

  const classes = useStyles();

  useEffect(() => {
    getRelatorio()
  }, [])

  const activeTab = localStorage.getItem(localStorageActiveTabKey);

  const [meses, setMeses] = useState([])
  const [quantidadeAtendimento, setQuantidadeAtendimento] = useState(null)
  const [notaMedia, setNotaMedia] = useState(null)

  const htmlClassService = useHtmlClassService();

  async function getRelatorio() {
    try {
      // setIsLoadingNota(() => true)

      let { data } = await api.get("/atendimento/relatorio")

      let { meses, total_atendimentos, nota_media } = data

      setMeses(meses)
      setQuantidadeAtendimento(total_atendimentos)
      setNotaMedia(nota_media)
    } catch (error) {

    } finally {
      // setIsLoadingNota(() => false)
    }
  }

  return (
    <>
      <Row>
        <Col sm="6">
          <Paper className={classes.root} style={{ padding: 20, paddingBottom: 10, textAlign: 'center' }}>
            <p>Quantidade de atendimentos</p>
            <h3>{quantidadeAtendimento}</h3>
          </Paper>
        </Col>
        <Col sm="6">
          <Paper className={classes.root} style={{ padding: 20, paddingBottom: 10, textAlign: 'center' }}>
            <p>Nota média</p>
            <h3>{notaMedia}</h3>
          </Paper>
        </Col>
      </Row>

      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow style={{ marginTop: 20 }}>
              <TableCell>Mês/Ano</TableCell>
              <TableCell>Atendimentos</TableCell>
              <TableCell>Quantidade de notas</TableCell>
              <TableCell>Nota média</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody >
            {meses?.map((mes, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {mes.mes < 10 ? `0${mes.mes}` : mes.mes}/{mes.ano}
                </TableCell>
                <TableCell component="th" scope="row">{mes.quantidade_atendimento}</TableCell>
                <TableCell component="th" scope="row">{mes.nota_atendimento == null ? "Nenhum" : mes.nota_atendimento}</TableCell>
                <TableCell component="th" scope="row">{mes.nota_atendimento == null ? "-" : mes.nota_media}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

    </>
  );
}