import React, { useEffect, useState } from "react"

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Visibility, AddCircle, DeleteForever, List } from '@material-ui/icons';
import { Options } from "./Options";

export default function RowEtapa({ item, etapas: ets, setData, onRemoveOption, handleOpenModal, handleDelete }) {
    const [optionsShow, setOptionsShow] = useState(false)

    const [etapas, setEtapas] = useState([])

    useEffect(() => {
        setEtapas(() => ets)
    }, [ets])

    const TypeBadge = ({ tipo }) => {
        let tipos = {
            envio: { text: "Envio", background: "black", color: "white" },
            funcao: { text: "Função", background: "blue", color: "white" },
            entrada: { text: "Entrada", background: "green", color: "white" },
            opcoes: { text: "Opções", background: "yellow", color: "black" },
            "opcoes-personalizadas": { text: "Opções Personalizadas", background: "orange", color: "black" },
        }

        return (
            <div style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                <div style={{ backgroundColor: tipos[tipo].background, borderRadius: 10, padding: 10, fontSize: 12, width: 150, textAlign: "center" }}>
                    <p style={{ margin: 0, color: tipos[tipo]?.color }}>{tipos[tipo]?.text}</p>
                </div>
            </div>
        )
    }


    const ContentEnvio = ({ mensagem }) => <p>{mensagem}</p>

    const ContentFuncao = ({ funcao }) => <i style={{ fontSize: 14 }}>Chamada para função {funcao}</i>

    const ContentEntrada = ({ mensagem, chave }) => <>
        <p>{mensagem}</p>
        <i style={{ fontSize: 12 }}>Chave: {chave || "Sem chave"}</i>
    </>

    const ContentOpcoes = ({ mensagem, opcoes, salvar, chave }) => <>
        {mensagem ? <p>{mensagem}</p> : <i style={{ fontSize: 12 }}>Sem mensagem</i>}
        <hr />
        {opcoes.map((opcao, index) => <>
            <p>
                <i style={{ fontSize: 12, marginRight: 10 }}>({opcao.entrada})</i>
                {opcao.entrada_visualizacao}
                {opcao.etapa_proxima_codigo && <i style={{ fontSize: 12 }}>(Ir para {opcao.etapa_proxima_codigo})</i>}
            </p>
            {opcao.mensagem && <p style={{ marginLeft: 30 }}><b>Mensagem: </b>{opcao.mensagem}</p>}
        </>)}
        <hr />
        <i style={{ fontSize: 12 }}>{salvar ? `Salvar na variável ${chave}` : "Não salvar opção escolhida"}</i>
    </>

    const ContentOpcoesPersonalizadas = ({ mensagem, salvar, chave }) => <>
        {mensagem ? <p>{mensagem}</p> : <i style={{ fontSize: 12 }}>Sem mensagem</i>}
        <hr />
        <i style={{ fontSize: 12 }}>{salvar ? `Salvar na variável ${chave}` : "Não salvar opção escolhida"}</i>
    </>

    const Content = ({ tipo, ...item }) => {
        if (tipo == "envio") return <ContentEnvio {...item} />
        if (tipo == "funcao") return <ContentFuncao {...item} />
        if (tipo == "entrada") return <ContentEntrada {...item} />
        if (tipo == "opcoes") return optionsShow ? <Options setData={setData} onRemoveOption={onRemoveOption} etapas={etapas} etapa={item} /> : <ContentOpcoes {...item} />
        if (tipo == "opcoes-personalizadas") return <ContentOpcoesPersonalizadas {...item} />
        return <></>
    }

    return (
        <TableRow key={item.id} style={item.status == 1 ? { backgroundColor: "rgba(60, 170, 10, 0.2)" } : {}}>
            <TableCell component="th" scope="row">
                <TypeBadge tipo={item.tipo} />
            </TableCell>
            <TableCell component="th" scope="row">
                {item.codigo}
            </TableCell>
            <TableCell component="th" scope="row">
                <Content {...item} />
            </TableCell>
            <TableCell component="th" scope="row">
                {item.etapa_proxima_codigo || <i style={{ fontSize: 12 }}>Fim de fluxo</i>}
            </TableCell>
            <TableCell align="right">
                {item.tipo == "opcoes" &&
                    <button style={
                        { backgroundColor: 'transparent', border: 'none', height: 'auto' }}
                        onClick={() => setOptionsShow(show => !show)}
                    >
                        <List style={{ color: '#333' }} />
                    </button>
                }

                <button style={
                    { backgroundColor: 'transparent', border: 'none', height: 'auto' }}
                    onClick={() => handleOpenModal('see', item)}
                >
                    <Visibility style={{ color: '#333' }} />
                </button>

                {!["NA-100", "NA-200"].includes(item.codigo) &&
                    <button style={
                        { backgroundColor: 'transparent', border: 'none', height: 'auto' }}
                        onClick={async () => {
                            await handleDelete(item.id)
                            setOptionsShow(false)
                        }}
                    >
                        <DeleteForever style={{ color: '#333' }} />
                    </button>
                }
            </TableCell>
        </TableRow>
    )
}